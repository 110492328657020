@import 'features/styles/base';

.project {
  padding: 24px;
  transition: border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  border: 1px solid transparent;

  &.isHighlighted {
    border: 1px solid var(--colorsBlue, #246FFF);
    box-shadow: 0px 4px 8px 0px $box-shadow-black, 0px 0px 0px 4px $box-shadow-blue;
  }
}

.tags {
  margin-bottom: 16px;

  > * + * {
    margin-left: 5px;
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  word-break: break-word;
}

.actions {
  margin-top: 10px;
  display: flex;

  > * + * {
    margin-left: 8px;
  }
}
