@import '@monite/ui/scss/vars';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;

  aside {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;

    > * + * {
      margin-left: 8px;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.breadcrumbs {
  margin-left: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--colorsGrey, #707070);

  > * + * {
    margin-left: 8px;
  }

  > span {
    display: inline-block;
    vertical-align: middle;
    word-break: break-word;
  }

  a {
    color: var(--colorsGrey, #707070);
    text-decoration: underline;

    &:hover {
      color: var(--colorsBlack, #111111);
    }
  }

  i {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  svg {
    color: var(--colorsGrey, #707070);
    display: inline-block;
  }
}

.title {
  margin-left: 8px;
}
