@import 'features/styles/base';

.modal {
  width: 580px;
  > div {
    padding: 12px 32px 24px 32px;
  }
}

.wrapper {
  margin-top: -20px;
}

.actions {
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-between;

  button {
    width: 116px;
  }
}

.header {
  padding: 32px 32px 0 32px;
  text-align: center;

  > * + * {
    margin-top: 16px;
  }
}

.form {
  margin: 32px 0;
  border-radius: 8px;
  border: 1px solid #dddddd;
  .addButton {
    text-align: start;
    &:hover:not(:disabled) {
      color: white;
      background: #111111;
      border-radius: 0 0 8px 8px;
    }
  }
}

.titleRow {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #dddddd;
  > div {
    flex-grow: 1;
  }
}

.formRow {
  display: flex;
  padding: 24px 16px;
  border-bottom: 1px solid #dddddd;

  > div {
    width: 50%;
    margin-right: 16px;
  }

  .trashButton {
    display: none;
    margin-left: 4px;
    align-self: baseline;
    margin-top: 20px;
    &:hover {
      path {
        fill: #ff475d;
      }
    }
  }
}

.formRow:not(.isSingle) {
  &:hover {
    background: #f3f3f3;
    .trashButton {
      display: block;
    }

    input {
      border: 1px solid #dddddd;
    }

    .select > div {
      border: 1px solid #dddddd;
    }
  }
}
