@import 'features/styles/base';

.modal {
  & > div {
    overflow: visible;
  }
}

.separator {
  margin: 24px 0;
  height: 1px;
  background: var(--colorsLightGrey2, #dddddd);
}

.actions {
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-between;
}

.options {
  padding: 0 32px;

  > * + * {
    margin-top: 8px;
  }
}

.header {
  padding: 32px 32px 0 32px;
  text-align: center;
  > div {
    white-space: pre-line;
  }
  > * + * {
    margin-top: 16px;
  }
}

.revokeDateField {
  margin: 24px 0;
  width: 368px;
  padding-left: 16px;
}

.dateTip {
  color: #b8b8b8;
}

.textDanger {
  color: var(--colorsDanger, #ff0000);
  background-color: var(--colorsPink, #ff0000);
  padding: 0.8em 1em;
  border-radius: 0.5em;
}
