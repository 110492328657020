.breadcrumbs {
  padding: 28px 28px;
  border-bottom: 1px solid var(--colorsLightGrey2, #dddddd);
  display: flex;
  align-items: center;

  color: var(--colorsLightGrey1, #B8B8B8);

  .active {
    color: var(--colorsBlack, #111111);
  }

  > * + * {
    margin-left: 8px;
  }
}

.content {
  padding: 48px 32px 35px 32px;
  flex: 1;

  overflow-y: scroll;

  form {
    > * + * {
      margin-top: 24px;
    }
  }

  .avatarInput {
    position: relative;
    margin-bottom: 20px;
  }
}

.buttons {
  display: flex;
  padding: 16px 32px;
  border-top: 1px solid var(--colorsLightGrey2, #dddddd);

  > * + * {
    margin-left: 12px;
  }
}

.keys {
  > div {
    > div:first-child {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > div:nth-child(2) {
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > div:last-child {
      text-align: right;
      justify-content: flex-end;
    }
  }
}

.passwords {
  padding: 32px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;

  > * + * {
    margin-top: 24px;
  }
}

.form button {
  display: block;
  margin-top: 24px;
  min-width: 165px;
}