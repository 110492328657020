.card {
  padding: 48px 16px;

  > div {
    max-width: 368px;
    margin: 0 auto;

    form {
      > * + * {
        margin-top: 24px;
      }

      button[type='submit'] {
        width: 100%;
      }
    }
  }
}

.cardError {
  color: var(--colorsError);
}

.button {
  &:hover {
    background-color: var(--colorsGrey);
  }
}
