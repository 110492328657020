.wrapper > div {
  width: 580px;
}

.button {
  width: 158px;
  margin-top: 32px;
}

.content {
  padding: 0 18px;
  margin-top: 12px;
}
