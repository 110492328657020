.link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  > * + * {
    margin-left: 12px;
  }

  a {
    font-weight: 500;
    display: inline-block;
    margin-left: 12px;
  }

  button {
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .action {
    display: inline-block;
    margin-left: 12px;
    color: var(--colorsGrey, #707070);
  }

  &.secondary {
    color: var(--colorsGrey, #707070);

    a {
      color: var(--colorsGrey, #707070);
      font-weight: 400;
      text-decoration: underline;
    }
  }
}
