body,
.layout {
  background: var(--colorsLightGrey3, #f3f3f3);
}

a {
  color: var(--colorsBlue, #246fff);
  text-decoration: none;

  &:hover {
    color: var(--colorsPrimaryDarker, #1D59CC);
  }
}

.wrapper {
  width: 496px;
  max-width: 100vw;
  margin: 0px auto;
  padding: 80px 0;

  > * + * {
    margin-block-start: 32px;
  }
}

.logo {
  text-align: center;

  img {
    display: inline-block;
    width: 120px;
  }
}

.content {
  background: white;
  border: 1px solid var(--colorsLightGrey2, #dddddd);
  border-radius: 8px;
  padding: 47px 46px 47px 46px;

  display: flex;
  flex-direction: column;

  text-align: center;

  > * + * {
    margin-block-start: 24px;
  }

  h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;

    > * + * {
      margin-block-start: 24px;
    }

    button[type='submit'] {
      margin-block-start: 40px;
      width: 100%;
    }
  }
}
