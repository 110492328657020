@import 'features/styles/base';

.modal {
  width: 580px;
  > div {
    padding: 12px 32px 24px 32px;
  }
}

.wrapper {
  margin-top: -20px;
}

.actions {
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-between;

  button {
    width: 116px;
  }
}

.header {
  padding: 32px 32px 0 32px;
  text-align: center;

  > * + * {
    margin-top: 16px;
  }
}

.form {
  margin: 32px 0;
}

.titleRow {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #dddddd;
  > div {
    flex-grow: 1;
  }
}
