.container {
  box-shadow: none !important;
  width: auto !important;
  max-width: 274px;
}

.toast {
  background: var(--colorsBlack, #111111) !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
}

.body {
  padding: 0 !important;

  font-family: 'Faktum';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;

  a {
    color: #FFFFFF;
    text-decoration: underline;
  }

  button {
    span {
      text-decoration: underline;
    }
  }
}


