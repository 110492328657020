.card {
  padding: 0px 16px 16px 16px;
}

.table {
  th {
    padding: 20px 12px;

    &:first-child {
      padding-left: 16px;
    }
  }

  td {
    padding: 20px 12px;
  }
}

.status,
.createdBy {
  width: 30%;
}

.date {
  width: 15%;
}

.empty {
  padding: 56px 24px;

  text-align: center;

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  div {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 10px 0 24px 0;
  }
}
