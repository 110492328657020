.card {
  padding: 0px 16px 16px 16px;
}

.table {
  th {
    &:nth-child(2) {
      width: 150px;
    }

    &:nth-child(3) {
      width: 270px;
    }

    &:nth-child(4) {
      width: 160px;
    }

    &:last-child {
      width: 1px;
    }
  }
}
