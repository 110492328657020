.card {
  padding: 56px;

  form {
    max-width: 368px;
    margin: 0 auto;

    > * + * {
      margin-top: 24px;
    }
  }
  button[type='submit'] {
    width: 100%;
  }
}
