.content {
  padding: 0 16px;

  > * + * {
    margin-top: 24px;
  }
}

.alert {
  display: flex;
  flex-direction: row;

  position: relative;
  padding: 12px;

  border: 1px solid var(--colorsLightGrey2, #DDDDDD);
  border-radius: 8px;

  > * + * {
    margin-left: 8px;
  }

  > div:first-child {
    flex-shrink: 0;
    color: var(--colorsOrange2, #F9A03F);
  }
}
