@import '@monite/ui/scss/vars';

.layout {
  display: grid;
  grid-template-columns: 281px 1fr;
}

.header {
  border-bottom: 1px solid var(--colorsLightGrey2, #dddddd);

  display: grid;
  grid-template-columns: 281px 1fr;

  grid-column: 1 / 3;
  grid-row: 1;

  .leftCol {
    padding: 24px 48px;
    display: flex;
    align-items: center;

    img {
      height: 20px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .rightCol {
    padding: 24px 48px;

    .profile {
      margin-left: auto;
      justify-content: right;
      max-width: 200px;
      color: var(--colorsBlack, #111111);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .mobile {
    display: none;
    grid-column: 1 / 3;

    div {
      display: flex;

      a {
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        display: inline-block;
        min-width: 32px;
        min-height: 32px;

        margin-right: 12px;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
    }
  }

  @include media-breakpoint-down(md) {
    background: white;
    border-bottom: none;
    position: sticky;
    top: 0;
    z-index: 900;
  }
}

.asideCol {
  grid-column: 1;
  grid-row: 2;

  padding: 48px 0 24px 24px;

  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .currentProjectMenuLink {
    margin-bottom: 16px;
    &.nonClickable {
      cursor: default;
    }
    &:hover {
      background-color: transparent;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;

    overflow-y: scroll;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--colorsBlack, #111111);
    height: 100vh;
    z-index: 920;
    padding: 24px 0 24px 0;

    &.shown {
      display: block;
    }
  }
}

.contentCol {
  grid-column: 2;
  grid-row: 2;
  padding: 48px;

  .content {
    max-width: 1440px;
    margin: 0 auto;

    > * + * {
      margin-top: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    grid-column: 1 / 3;

    padding: 0 16px 16px 16px;
  }
}

.backdrop {
  opacity: 0.1;
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 910;
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  &.shown {
    display: block;
  }
}

.footerMenuLink {
  position: fixed;
  bottom: 40px;
}
