.content {
  padding: 0 18px;
  text-align: left;

  > * + * {
    margin-block-start: 24px;
  }
}

.button {
  margin-top: 32px;
}
