@import '@monite/ui/scss/vars';

.menuLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--colorsBlack, #111111);
  border-radius: 4px;

  border: none;
  background: none;
  cursor: pointer;
  text-align: left;

  span {
    width: 133px;
    max-height: 32px;
    padding: 4px 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.noLeftIcon {
    margin-left: 44px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }

  &.active {
    background: var(--colorsLightGrey2, #dddddd);
  }

  &:hover {
    background: var(--colorsLightGrey2, #dddddd);
  }

  @include media-breakpoint-down(md) {
    width: 280px;
    border-radius: 0px;
    display: flex;
    color: var(--colorsWhite, #ffffff);

    svg {
      color: var(--colorsWhite, #ffffff);
    }

    &:hover {
      background: var(--colorsDarkGrey, #3b3b3b);
    }

    span {
      flex: 1;
    }
  }
}

span {
  text-overflow: ellipsis;
  overflow: hidden;}
  
.menuLinkLeftLogoIcon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px 32px;

  margin-right: 12px;
}

.menuLinkRightIcon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px 24px;

  margin-left: 8px;
}
